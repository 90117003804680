import { lazy } from 'react';

export const moduleConfig = {
  1: lazy(() => import('../pages/Dashboard.js')),
  2: lazy(() => import('../views/SwingDoorWardrobe/Main.js')),
  3: lazy(() => import('../pages/Development.js')),
  4: lazy(() => import('../pages/Development.js')),
  5: lazy(() => import('../pages/Development.js')),
  99: lazy(() => import('../views/Settings/Settings.js')),
};
