export const menuData = [
  {
    MenuID: 1,
    MenuName: "Dashboard",
    IsVisible: true,
    IsSubMenu: false,
    IconName: "dashboard",
  },
  {
    MenuID: 2,
    MenuName: "Wardobe",
    IsVisible: true,
    IsSubMenu: false,
    IconName: "door_sliding",
  },
  {
    MenuID: 3,
    MenuName: "Door",
    IsVisible: true,
    IsSubMenu: false,
    IconName: "door_front",
  },
  {
    MenuID: 4,
    MenuName: "Box",
    IsVisible: true,
    IsSubMenu: false,
    IconName: "select_all",
  },
  {
    MenuID: 5,
    MenuName: "Kitchen",
    IsVisible: true,
    IsSubMenu: false,
    IconName: "kitchen",
  },
  {
    MenuID: 99,
    MenuName: "Settings",
    ParentRefID: 1,
    IsVisible: true,
    IsSubMenu: false,
    IconName: "settings",
  },
];
